import React, {CSSProperties, PropsWithChildren} from "react"
import {apiPost} from "../api"
import StyleClasses from "../classes.module.sass"
import {FaEye, FaFileCsv, FaFilePdf, FaTable} from "react-icons/fa"
import {downloadAsCsv, downloadAsExcel, downloadAsPdf} from "../download"
import Status from "../status"
import ReactTable, {Column, Filter, FilterRender} from "react-table"
import "react-table/react-table.css"
import {MiniBtn} from "./components"
import {MyModal} from "./modal"
import myDeepQual from "../myDeepEqual"
import {getSubProps, objGetEntries} from "../helpers"
import {ColumnSelector} from "./table/column_selector"
// @ts-ignore
import Select from "react-select"
import {MultiValueContainer} from "../../user/user_history"

export interface CellParams<T, V> {
    original: T,
    value: V,
    row: any
}

export type Cell<T, V = any> = (p: CellParams<T, V>) => any
export type Accessor<T> = (t: T) => any

export interface LabelValuePair<S = any, T = any> {
    label: S,
    value: T
}

export type OptionalLabelValuePair<S = any, T = any> = Pick<LabelValuePair<S, T>, "value"> & Partial<Pick<LabelValuePair<S, T>, "label">>

export interface ColumnType<T, V = any> extends Partial<Column.FilterProps> {
    Header?: string | JSX.Element,
    Cell?: Cell<T, V>,
    show?: boolean,
    id?: string,
    accessor?: string | Accessor<T>,
    key?: string,
    defaultHidden?: boolean,
    maxWidth?: number,
    minWidth?: number,
    pdfCell?: Cell<T, V>,
    pdfShow?: boolean,
    pdfAlign?: "right" | "center" | "left",
    filterable?: boolean,
    sortable?: boolean,
    width?: number,
    pdfWidth?: number,
    Filter?: FilterRender,
    filterType?: "text" | "boolean" | "select"
    selectables?: LabelValuePair[]
    selectCompareFunction?: (obj?: T, selected?: LabelValuePair[]) => boolean
    timeout?: number
}

type ColumnsChangeType<T> = ((t: (string | undefined)[]) => any)

interface MyReactTableProps<T> {
    pageSizeDef?: any
    columns: ColumnType<T>[]
    configTitle?: string
    columnsConfigurable?: boolean
    title?: string
    columnsChange?: ColumnsChangeType<T>
    onTableChange?: Function
    additionalButtonsLeft?: any
    additionalButtonsRight?: any
    noPdf?: boolean
    noCsv?: boolean
    exportData?: boolean
    data: T[]
    additionalTitles?: any
    loading?: boolean
    sortable?: boolean
    getTrProps?: any
    resolveData?: any
    defaultSorted?: any
    onPageChange: any
    onFilteredChange?: (a: Filter[], oldFilter: Record<keyof T, any>, afterChange?: (data: T) => any) => any
    search?: Record<string, any>
    Filter?: FilterRender
    ignoreLocalStorageColumns?: string[]
}

interface MyReactTableState<T> {
    pageSize?: any
    columns: ColumnType<T>[]
    loading: boolean
    error?: string
    data?: T[]
    filter?: any
    selectValues?: Record<string, LabelValuePair[]>
    timer: NodeJS.Timeout | null
}

interface ColumnHeaderProps {
    toggleSort?: any
    className?: string
    children?: any
    sorted?: boolean
    key?: string
    style?: CSSProperties
}


export class MyReactTable<T = any> extends React.Component<MyReactTableProps<T>, MyReactTableState<T>> {
    static defaultProps = {
        data: [], columns: [], defaultSorted: [],
        onTableChange: (data: any) => {
        },
        pageSizeDef: 25, loading: false, onPageChange: null,
        manualUpdate: null, resolveData: null, onFilteredChange: null,
        sortable: true, getTrProps: ((e: any) => e), exportData: false
    }

    pageSizeOptions = [10, 25, 50, 100, 200]
    private tableRef: React.RefObject<any>
    private apiPost: any

    constructor(props: MyReactTableProps<T>) {
        super(props)
        this.apiPost = apiPost.bind(this)


        this.state = {
            pageSize: props.pageSizeDef,
            columns: props.columns,
            loading: false,
            filter: props.search || {},
            timer: null,
        }

        this.tableRef = React.createRef()
    }

    componentDidMount() {
        this.loadColumnsFromLocalStorage()
    }

    // LocalStorageLoad
    loadColumnsFromLocalStorage() {
        const {configTitle, title, columns, columnsChange,} = this.props

        let processedColumns = columns
            .filter(a => a.show !== false)
            .map(x => ({...x})) as ColumnType<T>[]
        let storedIDs = localStorage.getItem("MyReactTable" + (configTitle || title || JSON.stringify(columns.map(x => x.id || x.accessor))))

        if (!!storedIDs) {
            const storedIDsField = JSON.parse(storedIDs).reverse() || [] as number[]
            processedColumns = processedColumns
                .sort(
                    (a, d) => (storedIDsField || []).indexOf(a.id || a.accessor) < (storedIDsField || []).indexOf(d.id || d.accessor) ? 1 : -1
                )
                .map(a => ({...a, defaultHidden: !(storedIDsField || []).includes(a.id || a.accessor)}))
        }
        columnsChange && columnsChange(processedColumns.filter(a => !a.defaultHidden).map(a => a.id || (typeof a.accessor !== "function" ? a.accessor : undefined) || undefined))
        this.setState({columns: processedColumns})
    }

    updateColumns = (columns: ColumnType<T>[]) => {
        this.props.columnsChange && this.props.columnsChange(columns.filter(a => !a.defaultHidden).map(a => a.id || (typeof a.accessor !== "function" ? a.accessor : undefined)))
        this.setState({columns: columns}, () => {
            localStorage.setItem(
                "MyReactTable" + (this.props.configTitle || this.props.title || JSON.stringify(this.props.columns.map(x => x.id || x.accessor))),
                JSON.stringify(this.state.columns.filter(a => a && !a.defaultHidden && a.show !== false).map(a => a.id || a.accessor).filter(name => name && (!this.props.ignoreLocalStorageColumns || !this.props.ignoreLocalStorageColumns.includes("" + name))))
            )
        })
    }

    componentDidUpdate = (prevProps: MyReactTableProps<T>, prevState: MyReactTableState<T>) => {
        const columnsAreEqual = myDeepQual(this.props.columns, prevProps.columns)

        if (!columnsAreEqual) {
            this.loadColumnsFromLocalStorage()
        }
        if (!myDeepQual(this.props.search, prevProps.search)) {
            this.setState(s => ({...s, filter: this.props.search || {}}))
        }
        if (this.props.data.length !== prevProps.data.length || !columnsAreEqual) {
            const selectableMap: Record<string, LabelValuePair[]> = {}
            this.props.columns
                .filter(column => column.filterType === "select")
                .map(column => ({id: column.id || ("" + column.accessor), accessorFunction: typeof column.accessor === "function" ? column.accessor : undefined}))
                .forEach((current) => {
                    selectableMap[current.id] = this.props.data.map(row => typeof current.accessorFunction === "function" ? current.accessorFunction(row) : getSubProps(row, current.id)).filter((current, index, list) => list.indexOf(current) === index).sort().map(datum => ({
                        value: datum,
                        label: datum
                    }))
                })
            this.setState(s => ({...s, selectValues: selectableMap}))
        }
    }

    // Composites the JSX.Element buttons for the head row.
    TableHeader = ({toggleSort, className, children, sorted, style, ...rest}: PropsWithChildren<ColumnHeaderProps>) => {
        const {columnsConfigurable, columns, additionalButtonsLeft, additionalButtonsRight, exportData, noCsv, noPdf, data} = this.props
        if (className === "-headerGroups") {
            return <div>
                <div style={{float: "right"}} className={StyleClasses.AboveTableRight + " MyReactTable_buttonRow"}>
                    {
                        columnsConfigurable !== false &&
                        <MyModal trigger={<MiniBtn><FaEye/> Zeige / verstecke Spalten</MiniBtn>}>
                            {
                                (close: Function) => <ColumnSelector key={rest.key} columns={this.state.columns} onClose={close} onChange={this.updateColumns} resetColumns={columns}/>
                            }
                        </MyModal>
                    }
                    {
                        ((exportData && data?.length > 0) || !!additionalButtonsLeft || !!additionalButtonsRight) &&
                        <>
                            {!!additionalButtonsLeft && <>{additionalButtonsLeft}</>}
                            {exportData && data?.length > 0 &&
                                <>{!noPdf && <MiniBtn onClick={() => this.createPDF()}><FaFilePdf/> PDF erzeugen</MiniBtn>}
                                    {false && !noCsv && <MiniBtn onClick={() => this.createCSV()}><FaFileCsv/> CSV erzeugen</MiniBtn>}
                                    {!noCsv && <MiniBtn onClick={() => this.createExcel()}><FaTable/> Excel erzeugen</MiniBtn>}</>
                            }
                            {!!additionalButtonsRight && <>{additionalButtonsRight}</>}
                        </>
                    }
                </div>
            </div>
        }
        return <div className={'rt-thead ' + className} style={style} {...rest}>
            {children}
        </div>
    }


    createPDF = () => {
        this.setState({loading: true})
        this.apiPost("/export/pdf", this.prepareExportData(),
            (response: any) => {
                downloadAsPdf(response.content, "Export.pdf")
                this.setState({loading: false})
            }, (resp: any) => {
                this.setState({loading: false, error: "Es ist ein Fehler beim Erstellen des PDFs aufgetreten."})
            })
    }

    createCSV = () => {
        this.setState({loading: true})
        this.apiPost("/export/csv", this.prepareExportData(),
            (response: any) => {
                downloadAsCsv(response.content, "Export.csv")
                this.setState({loading: false})
            }, (resp: any) => {
                this.setState({loading: false, error: "Es ist ein Fehler beim Erstellen der CSV-Datei aufgetreten."})
            })
    }

    createExcel = () => {
        this.setState({loading: true})
        this.apiPost("/export/excel", this.prepareExportData(),
            (response: any) => {
                downloadAsExcel(response.content, "Export.xlsx")
                this.setState({loading: false})
            }, (resp: any) => {
                this.setState({loading: false, error: "Es ist ein Fehler beim Erstellen der Excel-Datei aufgetreten."})
            })
    }

    prepareExportData = () => {
        const data = this.tableRef.current.getResolvedState()?.sortedData
        const columns = this.tableRef.current.getResolvedState()?.columns[0]?.columns
        const columnLength = columns?.length
        let functionCallType = []
        let columnsData = []

        if (data.length === 0) {
            this.setState({loading: false, error: "Es gibt keine Daten zum Exportieren."})
        }

        for (let j = 0; j < columnLength; j++) {
            if (columns[j].pdfShow === false || (columns[j].pdfShow === undefined && columns[j].show === false)) {
                functionCallType.push(0)
                continue
            }

            columnsData.push({
                Header: columns[j]["pdfHeader"] || columns[j]["Header"],
                maxWidth: columns[j]["pdfMaxWidth"] || columns[j]["maxWidth"] || 1400,
                minWidth: columns[j]["pdfMinWidth"] || columns[j]["minWidth"] || 0,
                width: columns[j]["pdfWidth"] || columns[j]["width"] || "",
                textAlign: columns[j]["pdfAlign"] || "left",
            })

            let obj = {
                ...data[0],
                original: data[0]?._original,
                row: data[0]?._original,
                value: (typeof columns[j]["accessor"] === "function") ? columns[j]["accessor"](data[0]?._original) : data[0]?._original[columns[j]["accessor"]] || (data[0] || {})[columns[j]["accessor"]] || null
            }

            if (columns[j].pdfCell !== undefined) {
                const pdfValue = columns[j].pdfCell(obj)
                if (pdfValue !== Object(pdfValue)) {
                    functionCallType.push(1)
                    continue
                }
            }
            if (columns[j].Cell !== undefined) {
                const pdfValue = columns[j].Cell(obj)
                if (pdfValue !== Object(pdfValue)) {
                    functionCallType.push(2)
                    continue
                }
            }

            functionCallType.push(3)
        }

        function evalSafe(inp: string) {
            try {
                return eval(inp)
            } catch (e) {
                // console.log(e)
            }
            return null
        }

        let preparedData = []
        for (let i = 0; i < data.length; i++) {
            let preparedRow = []
            for (let j = 0; j < columnLength; j++) {
                if (functionCallType[j] === 0) {
                    continue
                }
                let obj = {
                    ...data[i],
                    original: data[i]?._original,
                    row: data[i]?._original,
                    value: (typeof columns[j]["accessor"] === "function") ? columns[j]["accessor"](data[i]?._original) : data[i]?._original[columns[j]["accessor"]] || (data[i] || {})[columns[j]["accessor"]] || evalSafe("data[i]?._original['" + (columns[j]["accessor"].split(".").join("']['")) + "']") || null
                }
                switch (functionCallType[j]) {
                    case 1:
                        preparedRow.push(columns[j].pdfCell(obj))
                        continue
                    case 2:
                        preparedRow.push(columns[j].Cell(obj))
                        continue
                    case 3:
                        preparedRow.push(obj.value)/*
                        const valueFunc = columns[j]["accessor"]
                        if (typeof valueFunc === 'function') {
                            preparedRow.push(valueFunc(data[i]["_original"] || data[i]))
                        } else {
                            preparedRow.push(data[i][valueFunc])
                        }*/
                }
            }

            preparedData.push(preparedRow)
        }

        return {data: preparedData, columns: columnsData, title: this.props.title || "Tabellen-Export", additionalTitles: this.props.additionalTitles || ""}
    }

    defaultTableFilterMethod = ({id, value: search}: any, row: any) => {
        // {label, value}[] - Select-Result
        const columnsSelectable = this.props.columns.filter(c => (c.id === id || c.accessor === id) && c.filterType === "select")
        if (columnsSelectable.length > 0) {
            if (columnsSelectable[0].selectCompareFunction) {
                return columnsSelectable[0].selectCompareFunction(row?._original, search)
            } else if (Array.isArray(search) && search.length > 0)
                return search.filter(s => s?.value == row[id] || s?.value === undefined).length > 0
            return true
        }
        if (typeof search === "boolean" || this.props.columns.filter(c => (c.id === id || c.accessor === id) && c.filterType === "boolean").length > 0) {
            return search === row[id]
        }

        let negativeMatching = false
        if (search[0] === "!") {
            negativeMatching = true
            search = search.slice(1)
            if (search === "") {
                return !row[id]
            }
            if (search === "!") {
                return !!row[id]
            }
        }
        if (typeof row[id] == 'boolean') {
            if (search.includes("j") || search.includes("w")) {
                return row[id]
            } else {
                return !row[id]
            }
        }
        const returnValue = (search: any) => {
            const searchFloat = parseFloat(search.slice(1))
            const searchFloatEqual = parseFloat(search.slice(2))
            if (search[0] === "<") {
                if (search[1] === "=") {
                    return row[id] !== undefined && (row[id] <= searchFloatEqual || search.length === 2)
                }
                return row[id] !== undefined && (row[id] < searchFloat || search.length === 1)
            }
            if (search[0] === ">") {
                if (search[1] === "=") {
                    return row[id] !== undefined && (row[id] >= searchFloatEqual || search.length === 2)
                }
                return row[id] !== undefined && (row[id] > searchFloat || search.length === 1)
            }
            if (id === "birthdate") {
                return (row[id] ?? "").toString().toLowerCase().startsWith(search.toLowerCase())
            } else {
                return (row[id] ?? "").toString().toLowerCase().includes(search.toLowerCase())
            }
        }
        if (search.includes(" UND ")) {
            return !search.split(" UND ").some((e: any) => !returnValue(e))
        }
        if (search.includes(" ODER ")) {
            return search.split(" ODER ").some((e: any) => returnValue(e))
        }

        if (negativeMatching) {
            return !returnValue(search)
        }
        return returnValue(search)
    }

    render = () => {
        const pushTableData = () => {
            return this.props.onTableChange !== undefined && this.tableRef.current !== null && this.tableRef.current.getResolvedState().sortedData !== undefined ? this.props.onTableChange(this.tableRef.current.getResolvedState().sortedData) : null
        }

        const preparedStateColumns = this.state.columns.filter(x => !x.defaultHidden)
        const preparedColumns = this.props.columns
            .filter(a => {
                return preparedStateColumns.findIndex(x => (x.id || x.accessor) === (a.id || a.accessor)) >= 0
            })
            .sort((a, b) => {
                const indexA = preparedStateColumns.findIndex(x => (x.id || x.accessor) === (a.id || a.accessor))
                const indexB = preparedStateColumns.findIndex(x => (x.id || x.accessor) === (b.id || b.accessor))
                return indexA - indexB
            }).map(column => {
                const identifier = column.id || ("" + column.accessor)
                const value = this.state.filter[identifier]
                switch (column.filterType) {
                    case "select":
                        const selectables = column.selectables || (this.state.selectValues || {})[identifier] || []
                        column.Filter = ({}) => {
                            return <Select
                                isMulti={true}
                                multi={true}
                                key={column.key}
                                onChange={(entry: LabelValuePair[]) => {
                                    this.setState(s => ({filter: {...s.filter, [identifier]: entry}}))
                                }}
                                components={{MultiValueContainer}}
                                closeMenuOnSelect={false}
                                options={selectables}
                                styles={{
                                    menuList: (base: any) => ({...base, zIndex: 100}),
                                    menu: (base: any) => ({...base, zIndex: 100, position: 'relative'})
                                }}
                                className="multi-select-with-see-more table-default-select"
                                classNamePrefix="select"
                                menuPosition={"fixed"}
                                placeholder={"Suche"}
                                menuPortalTarget={document.getElementById("react-select-portal")}
                                autosize={false}
                                value={value}
                            />
                        }
                        break
                    case "boolean":
                        column.Filter = ({}) => {
                            return <input key={column.key} type={"checkbox"} value={value} onChange={(e) => this.setState(s => ({filter: {...s.filter, [identifier]: e.target?.checked}}))}/>
                        }
                        break
                    default:
                    /*column.Filter = ({}) => {
                        return <input key={column.key} value={value} onChange={(e) => this.setState(s => ({filter: {...s.filter, [identifier]: e.target?.value}}))}/>
                    }*/
                }
                return column
            })

        return <>
            <Status type={"error"} text={this.state.error}/>
            <ReactTable
                TheadComponent={this.TableHeader}
                previousText={"vorherige Seite"}
                nextText={"nächste Seite"}
                rowsText={"Zeilen"}
                pageText={"Seite"}
                ofText={"von"}
                noDataText={"keine Zeilen vorhanden"}
                loading={this.state.loading || this.props.loading}
                loadingText={"Wird geladen ..."}
                sortable={this.props.sortable}
                getTrProps={this.props.getTrProps}
                // NextComponent={this.getNextComponent}
                // PaginationComponent={this.getCenterComponent}
                data={this.props.data}
                resolveData={this.props.resolveData || ((data: any) => data)}
                defaultFilterMethod={this.defaultTableFilterMethod}
                style={{width: "100%"}}
                columns={[
                    {
                        columns: preparedColumns
                    }
                ]
                }
                ref={this.tableRef}

                onSortedChange={pushTableData}
                onFetchData={pushTableData}
                defaultSorted={this.props.defaultSorted !== undefined ? this.props.defaultSorted : []}
                defaultFiltered={objGetEntries(this.state.filter || {}).map(x => ({id: x.key, value: x.value})) as Filter[]}
                showPagination={this.props.data?.length > this.state.pageSize}
                pageSize={Math.min(this.props.data?.length, this.state.pageSize)}
                onPageChange={this.props.onPageChange}
                className="-striped -highlight"
                pageSizeOptions={this.pageSizeOptions}
                onPageSizeChange={(newPageSize: number) => this.setState({pageSize: newPageSize})}
                FilterComponent={this.props.Filter}
                onFilteredChange={(filters) => {
                    pushTableData()

                    const timeout = this.props.columns
                        .filter(column => filters.map(x => x.id).includes(column.id || ("" + column.accessor)))
                        .reduce((max, column) => Math.max(max, column.timeout || 0), 0)

                    if (!!this.props.onFilteredChange) {
                        this.state.timer && clearTimeout(this.state.timer)
                        const setStateAction = () => this.setState(curr => {
                            return {
                                ...curr,
                                filter: {
                                    ...curr.filter,
                                    ...(this.props.onFilteredChange && this.props.onFilteredChange(filters, this.state.filter) || {}),
                                }
                            }
                        })
                        if (timeout > 0) {
                            const timer = setTimeout(setStateAction, timeout)
                            this.setState({timer})
                        } else {
                            setStateAction()
                        }
                    }
                }}/>
        </>
    }
}

